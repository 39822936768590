export default {
    namespaced: true,
    state: {
        meetings: [],
        meeting_statuses: [],
    },
    mutations: {
        set_meetings(state, payload) {
            state.meetings = [...payload]
        },
        set_meeting_statuses(state, payload) {
            state.meeting_statuses = [...payload]
        },
    },
    getters: {
        get_meetings: state => state.meetings,
        get_meeting_statuses: state => state.meeting_statuses,
    },
    actions: {
        set_meetings({ commit }, payload) {
            commit('set_meetings', payload)
        },
        set_meeting_statuses({ commit }, payload) {
            commit('set_meeting_statuses', payload)
        },
    }
}