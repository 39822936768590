<template>
    <v-container class="mt-10" fluid>
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-row v-else>
            <v-col cols="12">
                <v-card elevation="0" outlined>
                    <v-card-title>
                        Meetings for {{ get_selected_company.name }}
                        <v-spacer></v-spacer>
                        <v-btn @click="openCreateMeetingDialog()" color="primary">
                            <v-icon>mdi-plus</v-icon>
                            Create Meeting
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :items="get_meetings" :headers="meeting_headers"
                            :hide-default-header="get_meetings.length == 0"
                            :hide-default-footer="get_meetings.length == 0" :sort-by.sync="sort_by"
                            :sort-desc.sync="sort_desc">
                            <template v-slot:item="{ item }">
                                <tr @click="openMeeting(item)" style="cursor:pointer;">
                                    <td>
                                        {{ $date(item.created_at).format('M/DD/YYYY') }}
                                        <br>
                                        Week {{ $date(item.created_at).week() }}
                                    </td>
                                    <td>
                                        <v-text-field v-model="item.name" @keyup.enter="updateMeeting(item)" @click.stop
                                            dense hide-details></v-text-field>
                                    </td>
                                    <td>
                                        <div v-html="item.agenda"
                                            style="max-width:400px;max-height:150px;overflow-y:auto;"></div>
                                    </td>
                                    <td>
                                        <div
                                            style="max-width:100px;overflow-y:auto;overflow-x:hidden;display:flex;align-items:start;flex-direction:column;">
                                            <label v-for="(access, index) in item.access" :key="index"
                                                class="access-label">
                                                {{ access.user.name }}
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <v-select v-model="item.meeting_status_id" @change="updateMeeting(item)"
                                            :items="get_meeting_statuses" @click.stop item-text="name" item-value="id"
                                            dense hide-details class="hide-border">
                                            <template v-slot:append>
                                                <v-icon color="blue" class="mt-1">mdi-chevron-down</v-icon>
                                            </template>
                                            <template #selection="{ item }">
                                                <v-icon :color="item.color" class="mr-1">mdi-circle</v-icon>
                                                <strong style="font-size:12px;">{{ item.name }}</strong>
                                            </template>
                                        </v-select>
                                    </td>
                                    <td>
                                        <v-btn @click.stop text>
                                            <vue-timepicker v-model="item.duration" @close="updateMeeting(item)"
                                                format="HH:mm" :minute-interval="5" hide-clear-button></vue-timepicker>
                                        </v-btn>
                                    </td>
                                    <td>
                                        <div style="display:flex;align-items:center;">
                                            <v-menu right bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn small dense icon v-bind="attrs" v-on="on" @click.stop>
                                                        <v-icon color="black">mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item @click="openDeleteMeetingDialog(item)">
                                                        <v-list-item-title>
                                                            Delete
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="create_meeting_dialog" persistent max-width="500">
            <v-card>
                <v-card-title>Create Meeting</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="new_meeting.name" label="Meeting Name *" outlined
                                dense></v-text-field>
                        </v-col>
                        <!-- <v-col cols="6">
                            <v-select v-model="new_meeting.meeting_status_id" :items="get_meeting_statuses"
                                item-text="name" item-value="id" label="Status" outlined dense></v-select>
                        </v-col> -->
                        <v-col cols="12">
                            <h4>Agenda</h4>
                            <wysiwyg v-model="new_meeting.agenda" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeCreateMeetingDialog()" text>Cancel</v-btn>
                    <v-btn @click="createMeeting()" color="primary">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="delete_meeting_dialog" persistent max-width="500">
            <v-card>
                <v-card-title>Are you sure you want to delete this meeting?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteMeetingDialog()" text>Cancel</v-btn>
                    <v-btn @click="deleteMeeting()" color="error">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'DashboardComponent',
    data: () => ({
        loading: false,
        app_url: null,
        api_url: null,
        users: [],
        create_meeting_dialog: false,
        new_due_date_menu: false,
        new_meeting: {
            name: null,
            agenda: null,
            meeting_status_id: null,
            duratioon: null,
        },
        meeting_headers: [
            { text: 'Created', value: 'created_at', width: '10%' },
            { text: 'Meeting Name', value: 'name', width: '25%' },
            { text: 'Agenda', value: 'agenda', width: '30%' },
            { text: 'Attendees', width: '15%' },
            { text: 'Status', value: 'meeting_status_id', width: '20%' },
            { text: 'Duration', value: 'duration', width: '10%' },
            { text: '', width: '1%', sortable: false },
        ],
        delete_meeting_dialog: false,
        meeting_to_delete: null,
        sort_by: 'id',
        sort_desc: true,
    }),
    created() {
        this.app_url = process.env.VUE_APP_URL
        this.api_url = process.env.VUE_APP_API_URL
    },
    mounted() {
        this.loading = true
        this.fetchMeetings()
        this.fetchUsers()
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'companies/get_selected_company',
            get_companies: 'companies/get_companies',
            get_meetings: 'meetings/get_meetings',
            get_meeting_statuses: 'meetings/get_meeting_statuses',
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async fetchUsers() {
            await this.$axios.get(`api/users/get_users_by_company/${this.get_selected_company.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.users = data.data
                    }
                })
        },
        async fetchMeetings() {
            let payload = {
                // user_id: this.get_user.id,
                company_id: this.get_selected_company.id,
            }
            await this.$axios.get('api/meetings/get_meetings', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('meetings/set_meetings', data.data)
                        this.loading = false
                    }
                })
        },
        openMeeting(item) {
            this.$router.push({ name: 'ViewMeeting', params: { id: item.id } })
        },
        openCreateMeetingDialog() {
            this.create_meeting_dialog = true
        },
        closeCreateMeetingDialog() {
            this.create_meeting_dialog = false
            this.new_meeting = {
                name: null,
                agenda: null,
                meeting_status_id: null,
                duration: null,
            }
        },
        async createMeeting() {
            let payload = {
                company_id: this.get_selected_company.id,
                ...this.new_meeting
            }
            await this.$axios.post('api/meetings/create_meeting', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateMeetingDialog()
                        this.fetchMeetings()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        async updateMeeting(item) {
            let payload = {
                name: item.name,
                agenda: item.agenda,
                meeting_status_id: item.meeting_status_id,
                duration: item.duration,
            }
            await this.$axios.post(`api/meetings/update_meeting/${item.id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeleteMeetingDialog(item) {
            this.delete_meeting_dialog = true
            this.meeting_to_delete = item
        },
        closeDeleteMeetingDialog() {
            this.delete_meeting_dialog = false
            this.meeting_to_delete = null
        },
        async deleteMeeting() {
            await this.$axios.delete(`api/meetings/delete_meeting/${this.meeting_to_delete.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteMeetingDialog()
                        this.fetchMeetings()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
    },
}
</script>

<style lang="scss" scoped>
:deep(.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td) {
    border-bottom: none;
}

:deep(.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr) {
    height: 100px;
}

:deep(.theme--light.v-data-table .v-data-footer) {
    border-top: none;
}

:deep(.v-input__slot::before) {
    border-color: rgb(221, 221, 221) !important;
}

:deep(.hide-border>.v-input__control>.v-input__slot:before, .hide-border>.v-input__control>.v-input__slot:after) {
    border: none !important;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    min-width: 120px;
    height: 50px;
    align-items: center;
    display: flex;
    background-color: white;
    text-align: center;
    padding: 5px 10px;
    border: 1px solid grey;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 10;
    left: 110%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

:deep(.toggle-am-pm) {
    display: none;
}

.access-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 12px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
    margin-right: 5px;
}
</style>