import MeetingsComponent from '../MeetingsComponent.vue'
import ViewMeetingComponent from '../ViewMeetingComponent.vue'

export default [
    {
        path: '/meetings',
        name: 'Meetings',
        meta: { layout: 'default' },
        component: MeetingsComponent,
    },
    {
        path: '/view_meeting/:id',
        name: 'ViewMeeting',
        meta: { layout: 'default' },
        component: ViewMeetingComponent,
    },
]