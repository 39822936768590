<template>
  <v-container class="mt-10" fluid>
    <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
    <v-row v-else>
      <v-col cols="12">
        <v-btn @click="openShowFiltersDialog()" text small class="ml-5">
          <v-icon>mdi-filter</v-icon>
          Filters
        </v-btn>
        <v-card elevation="0" outlined>
          <v-card-title>
            <label style="font-size:16px;">
              Your tasks <label v-if="!is_company_selected"><u>across all companies</u></label>
              <label v-else>for <u>{{ get_selected_company.name }}</u></label>
            </label>
            <v-spacer></v-spacer>
            <div v-if="selected_tasks.length > 0">
              <small>
                {{ selected_tasks.length + ' task' + (selected_tasks.length == 1 ? '' : 's') }} selected
              </small>
              <!-- <v-btn @click="openEditBulkTasksDialog()" text>Edit</v-btn> -->
              <v-btn @click="openDeleteBulkTasksDialog()" color="error" text>Delete</v-btn>
            </div>
            <v-btn @click="openCreateTaskDialog('self')" color="primary">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add Task
            </v-btn>
          </v-card-title>
          <v-card-text>
            <TaskTableComponent :task_type="'personal'" :tasks="personal_tasks" :users="users"
              :selected_assignee="selected_assignee" :tags="tags" @getTasks="getTasksPersonal" @getTags="getTags" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card elevation="0" outlined>
          <v-card-title>
            <div style="display:flex;align-content:center;font-size:16px;">
              <v-select v-model="selected_assign_filter" v-if="selected_assignee.id != get_user.id"
                :disabled="get_user.role_id == 3" @change="getTasksAssigned()" :items="['All', 'My']" dense class="mx-5"
                style="max-width:100px;"></v-select>
              <label>Tasks Assigned To</label>
              <v-select v-model="selected_assignee" @change="getTasksAssigned()"
                :items="[{ name: 'You', id: get_user.id }, { name: 'Everyone', id: -1 }, ...users.filter((x) => x.id != get_user.id)]"
                item-text="name" item-value="id" dense class="mx-5" style="max-width:200px;" return-object></v-select>
              <v-select v-model="selected_tags" @change="getTasksAssigned()" :items="tags" item-text="name.en"
                item-value="name.en" :return-object="false" dense label="Filter by Tag" clearable></v-select>
            </div>
            <v-spacer></v-spacer>
            <v-btn @click="openCreateTaskDialog('assign')" color="primary">
              <v-icon>mdi-plus</v-icon>
              Assign Task
            </v-btn>
          </v-card-title>
          <v-card-text>
            <TaskTableComponent :task_type="'assigned'" :tasks="assigned_tasks" :users="users"
              :selected_assignee="selected_assignee" :tags="tags" :loading="table_loading_assigned"
              @getTasks="getTasksAssigned" @getTags="getTags" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="create_task_dialog" persistent max-width="500">
      <v-card>
        <v-card-title>Create Task</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="new_task.name" label="Task Name *" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select v-model="new_task.assignee_id"
                :items="[{ name: 'You', id: get_user.id }, ...users.filter((x) => x.id != get_user.id)]"
                item-text="name" item-value="id" outlined dense label="Assign To *"></v-select>
            </v-col>
            <v-col cols="6">
              <v-select v-model="new_task.priority_id" :items="get_priorities" item-text="name" item-value="id"
                label="Priority" outlined dense></v-select>
            </v-col>
            <v-col cols="6">
              <v-menu v-model="new_due_date_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="new_task.due_date" label="Due Date" readonly v-bind="attrs" v-on="on" outlined
                    dense></v-text-field>
                </template>
                <v-date-picker v-model="new_task.due_date" @input="new_due_date_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="new_task.description" label="Description" outlined dense></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeCreateTaskDialog()" text>Cancel</v-btn>
          <v-btn @click="createTask()" color="primary">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_task_dialog" persistent max-width="500">
      <v-card>
        <v-card-title>Are you sure you want to delete this task?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDeleteTaskDialog()" text>Cancel</v-btn>
          <v-btn @click="deleteTask()" color="error">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_bulk_tasks_dialog" persistent max-width="500">
      <v-card v-if="selected_tasks">
        <v-card-title>Are you sure you want to delete these tasks?</v-card-title>
        <v-card-text>
          <div v-for="(task, index) in selected_tasks" :key="index">
            {{ task.name }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDeleteBulkTasksDialog()" text>Cancel</v-btn>
          <v-btn @click="deleteBulkTasks()" color="error">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="show_filters_dialog" persistent max-width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-select v-model="selected_priorities" :items="[{ name: 'None', id: null }, ...get_priorities]" multiple
            item-text="name" item-value="id" label="Filter by priority" @change="getTasks"></v-select>
          <v-select v-model="selected_statuses" :items="[{ name: 'None', id: null }, ...get_statuses]" multiple
            item-text="name" item-value="id" label="Filter by status" @change="getTasks"></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeShowFiltersDialog()" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskTableComponent from '../modules/TaskTableComponent.vue'
export default {
  name: 'DashboardComponent',
  components: {
    TaskTableComponent
  },
  data: () => ({
    loading: false,
    table_loading_personal: false,
    table_loading_assigned: false,
    api_url: null,
    is_company_selected: false,
    users: [],
    selected_tasks: [],
    selected_assignee: {
      id: null,
      name: null,
      access: [],
    },
    due_date_menu: [],
    due_date_menu_assigned: [],
    create_task_dialog: false,
    new_due_date_menu: false,
    new_task: {
      name: null,
      description: null,
      priority_id: null,
      due_date: null,
    },
    task_headers: [
      { text: 'Task Name', value: 'name', width: '41%', sortable: false },
      { text: 'Due Date', value: 'due_date', width: '15%', sortable: false },
      { text: 'Allocated Time', value: 'allocated_time', width: '12%', sortable: false },
      { text: 'Priority', value: 'priority_id', width: '14%', sortable: false },
      { text: 'Status', value: 'status_id', width: '17%', sortable: false },
      { text: '', width: '1%', sortable: false },
    ],
    delete_task_dialog: false,
    task_to_delete: null,
    task_details_dialog: false,
    original_selected_task: null,
    edit_bulk_tasks_dialog: false,
    delete_bulk_tasks_dialog: false,
    new_task_type: null,
    personal_tasks: [],
    assigned_tasks: [],
    selected_company: null,
    scheduled_tasks: [],
    companies_by_assignee: [],
    can_close_task_details_dialog: false,
    navigation: {
      width: 600,
      borderSize: 5
    },
    show_filters_dialog: false,
    create_quick_task: false,
    selected_assign_filter: '',
    tags: [],
    selected_tags: [],
  }),
  created() {
    this.api_url = process.env.VUE_APP_API_URL
  },
  mounted() {
    this.loading = true
    this.table_loading_personal = true
    this.table_loading_assigned = true
    this.selected_assignee.id = this.get_user.id
    this.selected_assignee.name = this.get_user.name
    this.selected_assignee.access = this.get_user.access
    this.checkSelectedCompany()
    this.getTasks()
    this.getUsers()
    this.getTags()
    // this.setBorderWidth()
    // this.setEvents()
  },
  computed: {
    ...mapGetters({
      get_user: 'auth/get_user',
      get_selected_company: 'companies/get_selected_company',
      get_companies: 'companies/get_companies',
      get_priorities: 'tasks/get_priorities',
      get_statuses: 'tasks/get_statuses',
      get_selected_priorities: 'tasks/get_selected_priorities',
      get_selected_statuses: 'tasks/get_selected_statuses',
    }),
    selected_priorities: {
      get() {
        return this.get_selected_priorities
      },
      set(new_priorities) {
        this.$store.dispatch('tasks/set_selected_priorities', new_priorities)
      }
    },
    selected_statuses: {
      get() {
        return this.get_selected_statuses
      },
      set(new_statuses) {
        this.$store.dispatch('tasks/set_selected_statuses', new_statuses)
      }
    },
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit("auth/setMessage",
        { show: true, message: message },
        { root: 1 })
    },
    checkSelectedCompany() {
      if (this.get_selected_company.id) {
        this.is_company_selected = true
      }
      if (this.get_user.role_id < 3) {
        this.selected_assign_filter = 'All'
      }
      else {
        this.selected_assign_filter = 'My'
      }
    },
    async getUsers() {
      if (this.is_company_selected == true) {
        await this.$axios.get(`api/users/get_users_by_company/${this.get_selected_company.id}`)
          .then(({ data }) => {
            if (data.response) {
              this.users = data.data
            }
          })
      }
      else {
        await this.$axios.get('api/users/get_users')
          .then(({ data }) => {
            if (data.response) {
              this.users = data.data
            }
          })
      }
    },
    async getTasksPersonal() {
      this.table_loading_personal = true
      this.getTasks()
    },
    async getTasksAssigned() {
      this.table_loading_assigned = true
      this.getTasks()
    },
    async getTasks() {
      let payload = {
        user_id: this.get_user.id,
        assignee_id: this.selected_assignee.id,
        company_id: this.get_selected_company.id,
        selected_assign_filter: this.selected_assign_filter,
      }
      if (this.selected_tags != null && this.selected_tags.length > 0) {
        payload.tags = this.selected_tags
      }
      await this.$axios.get('api/tasks/get_my_tasks', payload)
        .then(({ data }) => {
          if (data.response) {
            this.personal_tasks = data.personal_tasks.filter(x => this.selected_statuses.includes(x.status_id) && this.selected_priorities.includes(x.priority_id))
            this.assigned_tasks = data.assigned_tasks.filter(x => this.selected_statuses.includes(x.status_id) && this.selected_priorities.includes(x.priority_id))
            this.loading = false
            this.table_loading_personal = false
            this.table_loading_assigned = false
          }
        })
    },
    async getTags() {
      this.$axios.get('api/tasks/get_tags')
        .then(({ data }) => {
          if (data.response) {
            this.tags = data.data
          }
        })
    },
    openCreateTaskDialog(type) {
      this.new_task_type = type
      this.new_task.assignee_id = this.get_user.id
      this.create_task_dialog = true
      if (this.new_task_type == 'assign' && this.selected_assignee.id != null && this.selected_assignee.id != this.get_user.id) {
        this.companies_by_assignee = []
        this.new_task.assignee_id = this.selected_assignee.id
        this.selected_assignee.access.forEach(access => {
          this.companies_by_assignee.push(access.company)
        })
      }
    },
    getSelectedAssigneeCompanies() {
      this.companies_by_assignee = []
      if (this.selected_assignee.id != this.get_user.id) {
        this.selected_assignee.access.forEach(access => {
          this.companies_by_assignee.push(access.company)
        })
      }
    },
    closeCreateTaskDialog() {
      this.create_task_dialog = false
      this.new_task = {
        name: null,
        description: null,
        priority_id: null,
        due_date: null,
      }
      this.companies_by_assignee = []
    },
    async createTask() {
      let payload = {
        assignee_id: (this.new_task_type == 'self') ? this.get_user.id : this.selected_assignee.id, // If creating personal task, assign to this user, else assign to selected user
        company_id: (this.is_company_selected == true) ? this.get_selected_company.id : this.selected_company ? this.selected_company.id : null,
        ...this.new_task
      }
      await this.$axios.post('api/tasks/create_task', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$toast.success(data.message)
            this.closeCreateTaskDialog()
            this.getTasks()
          }
          else {
            this.$toast.error(data.message)
          }
        })
    },
    async updateTask(item) {
      let payload = {
        h_id: item.h_id,
        company_id: item.company_id,
        assignee_id: item.assignee_id,
        project_id: item.project_id,
        name: item.name,
        status_id: item.status_id,
        description: item.description,
        priority_id: item.priority_id,
        due_date: item.due_date,
        allocated_time: item.allocated_time,
      }
      await this.$axios.post(`api/tasks/update_task/${item.id}`, payload)
        .then(({ data }) => {
          if (data.response) {
            this.$toast.success(data.message)
            this.getTasks()
          }
          else {
            this.$toast.error(data.message)
          }
        })
    },
    selectTask(item) {
      let index = this.selected_tasks.findIndex((x) => x.id == item.id)
      if (index === -1) {
        this.selected_tasks.push(item)
      }
      else {
        this.selected_tasks.splice(index, 1)
      }
    },
    openEditBulkTasksDialog() {
      this.edit_bulk_tasks_dialog = true
    },
    closeEditBulkTasksDialog() {
      this.edit_bulk_tasks_dialog = false
    },
    openDeleteBulkTasksDialog() {
      this.delete_bulk_tasks_dialog = true
    },
    closeDeleteBulkTasksDialog() {
      this.delete_bulk_tasks_dialog = false
    },
    async deleteBulkTasks() {
      await this.$axios.post('api/tasks/delete_tasks', this.selected_tasks)
        .then(({ data }) => {
          if (data.response) {
            this.closeDeleteBulkTasksDialog()
            this.getTasks()
            this.selected_tasks = []
          }
        })
    },
    openShowFiltersDialog() {
      this.show_filters_dialog = true
    },
    closeShowFiltersDialog() {
      this.show_filters_dialog = false
    },
  },
}
</script>

<style lang="scss" scoped></style>