import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import store from './plugins/vuex'
import axios from './plugins/axios.js'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import '@/plugins/dayjs'

Vue.component('vue-timepicker', VueTimepicker)

import BlankLayout from './layouts/BlankLayout.vue'
import DefaultLayout from './layouts/DefaultLayout.vue'
Vue.component('default-layout', DefaultLayout)
Vue.component('blank-layout', BlankLayout)
Vue.config.productionTip = false

Vue.use(axios)
Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  position: POSITION.BOTTOM_RIGHT
})
let wysiwyg_config = {
  hideModules: { "image": true, "removeFormat": true, "code": true }
}
Vue.use(wysiwyg, wysiwyg_config)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')