export default {
    namespaced: true,
    state: {
        projects: [],
        project_statuses: [],
    },
    mutations: {
        set_projects(state, payload) {
            state.projects = [...payload]
        },
        set_project_statuses(state, payload) {
            state.project_statuses = [...payload]
        },
    },
    getters: {
        get_projects: state => state.projects,
        get_project_statuses: state => state.project_statuses,
    },
    actions: {
        set_projects({ commit }, payload) {
            commit('set_projects', payload)
        },
        set_project_statuses({ commit }, payload) {
            commit('set_project_statuses', payload)
        },
    }
}