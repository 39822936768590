import Vue from 'vue'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(weekOfYear)

Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs
        }
    }
})