import { render, staticRenderFns } from "./UserComponent.vue?vue&type=template&id=e0db5a6a&scoped=true"
import script from "./UserComponent.vue?vue&type=script&lang=js"
export * from "./UserComponent.vue?vue&type=script&lang=js"
import style0 from "./UserComponent.vue?vue&type=style&index=0&id=e0db5a6a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0db5a6a",
  null
  
)

export default component.exports