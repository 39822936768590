export default {
    namespaced: true,
    state: {
        companies: [],
        selected_company: {},
    },
    mutations: {
        set_companies(state, payload) {
            state.companies = [...payload]
        },
        set_selected_company(state, payload) {
            state.selected_company = payload
        },
    },
    getters: {
        get_companies: state => state.companies,
        get_selected_company: state => state.selected_company,
    },
    actions: {
        set_companies({ commit }, payload) {
            commit('set_companies', payload)
        },
        set_selected_company({ commit }, payload) {
            commit('set_selected_company', payload)
        },
    }
}