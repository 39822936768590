<template>
  <v-app>
    <v-main>
      <v-snackbar v-model="mSnackbar.show" bottom="bottom" multi-line="multi-line" :timeout="4000" :vertical="true">
        {{ mSnackbar.message }}<br />
      </v-snackbar>
      <component :is="layout">
        <router-view :key="$route.fullPath" />
      </component>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    layout() {
      return (this.$route.meta.layout || 'blank') + '-layout';
    },
    ...mapGetters({
      mSnackbar: 'auth/mSnackbar'
    })
  },
  components: {
  },
  data: () => ({
  }),
};
</script>

<style lang="scss">
</style>