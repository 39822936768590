export default {
    namespaced: true,
    state: {
        snackbar: {
            show: false,
            message: "",
            button: false,
        },
        user: {},
        token: '',
        roles: [],
    },
    mutations: {
        setMessage(state, v) {
            state.snackbar.show = v.show
            state.snackbar.message = v.message
            state.snackbar.button = v.button
        },
        set_user(state, payload) {
            state.user = payload
        },
        set_token(state, payload) {
            state.token = payload
        },
        set_roles(state, payload) {
            state.roles = [...payload]
        },
    },
    getters: {
        mSnackbar: state => state.snackbar,
        get_user: state => state.user,
        get_token: state => state.token,
        get_roles: state => state.roles,
    },
    actions: {
        set_user({ commit }, payload) {
            commit('set_user', payload)
        },
        set_token({ commit }, payload) {
            commit('set_token', payload)
        },
        set_roles({ commit }, payload) {
            commit('set_roles', payload)
        },
    }
}