<template>
    <v-container class="mt-10" fluid>
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-row v-else-if="project">
            <v-col cols="12">
                <v-card elevation="0" outlined>
                    <v-card-title style="display:flex;align-items:flex-start;">
                        <div v-if="edit_project_dialog" style="width:500px;">
                            <v-text-field v-model="project.name" @keyup.enter="updateProject()"
                                v-if="edit_project_dialog" outlined dense></v-text-field>
                            <v-select v-model="access_to_update" :items="users.filter(x => x.id != get_user.id)"
                                multiple item-text="name" item-value="id" outlined dense
                                label="Add Users to Project"></v-select>
                        </div>
                        <div v-else style="width:500px;">
                            <div>{{ project.name }}</div>
                            <small v-for="(access, index) in project.access" :key="index" class="access-label">
                                {{ access.user.name }}
                            </small>
                        </div>
                        <v-spacer></v-spacer>
                        <div v-if="get_user.role_id < 3">
                            <v-btn @click="editProject()" v-if="!edit_project_dialog" text>
                                <v-icon class="mr-2">mdi-pen</v-icon>Edit
                            </v-btn>
                            <v-btn @click="updateProject()" v-else text>
                                Save
                            </v-btn>
                            <v-btn @click="openDeleteProjectDialog()" text color="error">
                                <v-icon class="mr-2">mdi-delete</v-icon>
                                Delete Project
                            </v-btn>
                        </div>
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card elevation="0" outlined>
                    <v-card-title>
                        <label style="font-size:16px;">
                            Your tasks <label>for <u>{{ project.name }}</u></label>
                        </label>
                        <v-btn @click="openShowFiltersDialog()" text small class="ml-5">
                            <v-icon>mdi-filter</v-icon>
                            Filters
                        </v-btn>
                        <!-- <v-spacer></v-spacer>
                        <v-btn @click="openCreateTaskDialog('self')" color="primary">
                            <v-icon class="mr-2">mdi-plus</v-icon>
                            Add Task
                        </v-btn> -->
                    </v-card-title>
                    <v-card-text>
                        <TaskTableComponent :task_type="'personal'" :tasks="personal_tasks" :users="users"
                            :project_id="project_id" :tags="tags" :loading="table_loading_personal"
                            :selected_assignee="selected_assignee" @getTasks="getTasksPersonal" @getTags="getTags" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card elevation="0" outlined>
                    <v-card-title>
                        <div style="display:flex;align-content:center;font-size:16px;">
                            <v-select v-model="selected_assign_filter" v-if="selected_assignee.id != get_user.id"
                                :disabled="get_user.role_id == 3" @change="getTasksAssigned()" :items="['All', 'My']"
                                dense class="mx-5" style="max-width:100px;"></v-select>
                            <label>Tasks Assigned To</label>
                            <v-select v-model="selected_assignee" @change="getTasksAssigned()"
                                :items="[{ name: 'You', id: get_user.id }, { name: 'Everyone', id: -1 }, ...users.filter((x) => x.id != get_user.id)]"
                                item-text="name" item-value="id" dense class="mx-5" style="max-width:200px;"
                                return-object></v-select>
                            <v-select v-model="selected_tags" @change="getTasksAssigned()" :items="tags"
                                item-text="name.en" item-value="name.en" :return-object="false" dense
                                label="Filter by Tag" clearable></v-select>
                        </div>
                        <!-- <v-spacer></v-spacer>
                        <v-btn @click="openCreateTaskDialog('assign')" color="primary">
                            <v-icon>mdi-plus</v-icon>
                            Assign Task
                        </v-btn> -->
                    </v-card-title>
                    <v-card-text>
                        <TaskTableComponent :task_type="'assigned'" :tasks="assigned_tasks" :users="users"
                            :project_id="project_id" :tags="tags" :loading="table_loading_assigned"
                            :selected_assignee="selected_assignee" @getTasks="getTasksAssigned" @getTags="getTags" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="delete_project_dialog" persistent max-width="500">
            <v-card>
                <v-card-title>Are you sure you want to delete this project?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDeleteProjectDialog()" text>Cancel</v-btn>
                    <v-btn @click="deleteProject()" color="error">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="show_filters_dialog" persistent max-width="500">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-select v-model="selected_priorities" :items="[{ name: 'None', id: null }, ...get_priorities]"
                        multiple item-text="name" item-value="id" label="Filter by priority"
                        @change="getTasks"></v-select>
                    <v-select v-model="selected_statuses" :items="[{ name: 'None', id: null }, ...get_statuses]"
                        multiple item-text="name" item-value="id" label="Filter by status"
                        @change="getTasks"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeShowFiltersDialog()" text>Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskTableComponent from '../modules/TaskTableComponent.vue'
export default {
    name: 'ViewProjectComponent',
    components: {
        TaskTableComponent
    },
    data: () => ({
        loading: false,
        table_loading_personal: false,
        table_loading_assigned: false,
        app_url: null,
        api_url: null,
        project_id: null,
        project: null,
        users: [],
        access_to_update: [],
        selectable_users: [],
        edit_project: false,
        project_tasks: [],
        task_headers: [
            { text: 'Task Name', value: 'name', width: '41%', sortable: false },
            { text: 'Due Date', value: 'due_date', width: '15%', sortable: false },
            { text: 'Allocated Time', value: 'allocated_time', width: '12%', sortable: false },
            { text: 'Priority', value: 'priority_id', width: '14%', sortable: false },
            { text: 'Status', value: 'status_id', width: '17%', sortable: false },
            { text: '', width: '1%', sortable: false },
        ],
        personal_tasks: [],
        assigned_tasks: [],
        due_date_menu: [],
        create_task_dialog: false,
        new_due_date_menu: false,
        new_task: {
            name: null,
            assignee_id: null,
            description: null,
            priority_id: null,
            due_date: null,
        },
        task_details_dialog: false,
        selected_task: null,
        can_close_task_details_dialog: false,
        navigation: {
            width: 600,
            borderSize: 5
        },
        original_selected_task: null,
        create_quick_task: false,
        selected_assignee: {
            id: null,
            name: null,
            access: [],
        },
        edit_project_dialog: false,
        delete_project_dialog: false,
        show_filters_dialog: false,
        selected_assign_filter: '',
        tags: [],
        selected_tags: [],
    }),
    created() {
        this.app_url = process.env.VUE_APP_URL
        this.api_url = process.env.VUE_APP_API_URL
    },
    mounted() {
        this.loading = true
        this.table_loading = true
        this.selected_assignee.id = this.get_user.id
        this.selected_assignee.name = this.get_user.name
        this.selected_assignee.access = this.get_user.access
        this.getProject()
        this.getTags()
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_selected_company: 'companies/get_selected_company',
            get_companies: 'companies/get_companies',
            get_priorities: 'tasks/get_priorities',
            get_statuses: 'tasks/get_statuses',
            get_selected_priorities: 'tasks/get_selected_priorities',
            get_selected_statuses: 'tasks/get_selected_statuses',
        }),
        selected_priorities: {
            get() {
                return this.get_selected_priorities
            },
            set(new_priorities) {
                this.$store.dispatch('tasks/set_selected_priorities', new_priorities)
            }
        },
        selected_statuses: {
            get() {
                return this.get_selected_statuses
            },
            set(new_statuses) {
                this.$store.dispatch('tasks/set_selected_statuses', new_statuses)
            }
        },
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getProject() {
            this.project_id = parseInt(this.$route.params.id)
            if (this.get_user.role_id < 3) {
                this.selected_assign_filter = 'All'
            }
            else {
                this.selected_assign_filter = 'My'
            }
            await this.$axios.get(`api/projects/get_project/${this.project_id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.project = data.data
                        this.getUsers()
                        this.getTasks()
                        this.access_to_update = []
                        this.project.access.forEach(item => {
                            this.access_to_update.push(item.user_id)
                        })
                    }
                })
        },
        async getUsers() {
            await this.$axios.get(`api/users/get_users_by_company/${this.get_selected_company.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.users = data.data
                    }
                })
        },
        async getTasksPersonal() {
            this.table_loading_personal = true
            this.getTasks()
        },
        async getTasksAssigned() {
            this.table_loading_assigned = true
            this.getTasks()
        },
        async getTasks() {
            let payload = {
                user_id: this.get_user.id,
                assignee_id: this.selected_assignee.id,
                company_id: this.get_selected_company.id,
                project_id: this.project_id,
                selected_assign_filter: this.selected_assign_filter,
            }
            if (this.selected_tags != null && this.selected_tags.length > 0) {
                payload.tags = this.selected_tags
            }
            await this.$axios.get('api/tasks/get_my_tasks', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.personal_tasks = data.personal_tasks.filter(x => this.selected_statuses.includes(x.status_id) && this.selected_priorities.includes(x.priority_id))
                        this.assigned_tasks = data.assigned_tasks.filter(x => this.selected_statuses.includes(x.status_id) && this.selected_priorities.includes(x.priority_id))
                        this.loading = false
                        this.table_loading_personal = false
                        this.table_loading_assigned = false
                    }
                })
        },
        async getTags() {
            this.$axios.get('api/tasks/get_tags')
                .then(({ data }) => {
                    if (data.response) {
                        this.tags = data.data
                    }
                })
        },
        editProject() {
            this.edit_project_dialog = true
        },
        updateProject() {
            this.edit_project_dialog = false
            let payload = {
                ...this.project,
                access_to_update: this.access_to_update,
            }
            this.$axios.post(`api/projects/update_project/${this.project_id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.getProject()
                    }
                })
        },
        openDeleteProjectDialog() {
            this.delete_project_dialog = true
        },
        closeDeleteProjectDialog() {
            this.delete_project_dialog = false
        },
        async deleteProject() {
            await this.$axios.delete(`api/projects/delete_project/${this.project_id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.$router.push({ name: 'Dashboard' })
                    }
                })
        },
        openShowFiltersDialog() {
            this.show_filters_dialog = true
        },
        closeShowFiltersDialog() {
            this.show_filters_dialog = false
        },
    }
}
</script>

<style lang="scss" scoped>
:deep(.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr) {
    height: 50px;
}

:deep(.v-input__slot::before) {
    border-color: rgb(221, 221, 221) !important;
}

:deep(.hide-border>.v-input__control>.v-input__slot:before, .hide-border>.v-input__control>.v-input__slot:after) {
    border: none !important;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    min-width: 120px;
    height: 50px;
    align-items: center;
    display: flex;
    background-color: white;
    text-align: center;
    padding: 5px 10px;
    border: 1px solid grey;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 10;
    left: 110%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

:deep(.toggle-am-pm) {
    display: none;
}

.access-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 12px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
    margin-right: 5px;
}
</style>