<template>
    <div>
        <v-app-bar clipped-right color="#343642" dark>
            <v-app-bar-nav-icon @click.stop="drawer = true"
                v-if="this.$vuetify.breakpoint.mdAndDown"></v-app-bar-nav-icon>
            <v-toolbar-title>DG Ventures Project Management Software</v-toolbar-title>
        </v-app-bar>
        <slot></slot>
    </div>
</template>

<script>
export default {
    data: () => ({
        drawer: false,
    }),
    async mounted() {
    },
    computed: {
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
    },
}
</script>

<style scoped>
.company-menu {
    border: 1px solid #CCC;
    border-radius: 10px;
    cursor: pointer;
}

.company-menu-title {
    font-family: 'Manrope';
}

.company-menu-subtitle {
    font-size: 12px;
    font-family: 'Manrope';
}

.v-app-bar-title__content {
    width: 50% !important;
}

.main-app-bar {
    background-color: #FFF !important;
}

.main-app-bar-title {
    font-family: 'Manrope';
    font-size: 24px;
    font-weight: bolder !important;
}

.main-app-bar-subtitle {
    font-size: 10px;
}

.v-menu__content {
    box-shadow: none;
}

.v-data-table__wrapper {
    tr {
        cursor: pointer;
    }
}

::-webkit-scrollbar {
    width: 7px;
    background: white;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #D9D9D9;
    color: #D9D9D9;
    height: 50px;
}
</style>