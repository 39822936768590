<template>
    <v-container class="mt-10" fluid>
        <v-card elevation="0" outlined>
            <v-card-title>
                User Activities
                <v-spacer></v-spacer>
                <v-btn @click="getUserActivity()" color="primary">
                    <v-icon class="mr-2">mdi-restart</v-icon>
                    Refresh
                </v-btn>
            </v-card-title>
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-card-text v-else>
                <v-data-table :headers="headers" :items="user_activities" :sort-by.sync="sort_by"
                    :sort-desc.sync="sort_desc">
                    <template v-slot:item="{ item }">
                        <tr style="cursor:inherit;background-color:transparent;height:80px;">
                            <td>
                                {{ $date(item.created_at).format('MMMM DD, YYYY') }}
                                <br>
                                {{ $date(item.created_at).format('h:mm A') }}
                            </td>
                            <td>
                                <small><b>User ID: {{ item.user_id }}</b></small>
                                <br>
                                <label class="access-label">
                                    {{ item.user.name }}
                                </label>
                            </td>
                            <td>{{ item.message }}</td>
                            <td>
                                <div style="display:flex;">
                                    <div v-if="item.modified_user_id" class="mx-2"
                                        style="min-width:80px;max-width:200px">
                                        <small><b>User ID: {{ item.modified_user_id }}</b></small>
                                        <br>
                                        <label class="access-label" v-if="item.modified_user">
                                            {{ item.modified_user.name }}
                                        </label>
                                    </div>
                                    <div v-if="item.company_id" class="mx-2" style="min-width:80px;max-width:200px">
                                        <small><b>Company ID: {{ item.company_id }}</b></small>
                                        <br>
                                        <label class="access-label" v-if="item.company">
                                            {{ item.company.name }}
                                        </label>
                                    </div>
                                    <div v-if="item.task_id" class="mx-2" style="min-width:80px;max-width:200px">
                                        <small><b>Task ID: {{ item.task_id }}</b></small>
                                        <br>
                                        <label class="access-label" v-if="item.task">
                                            {{ item.task.name }}
                                        </label>
                                    </div>
                                    <div v-if="item.assignee_id" class="mx-2" style="min-width:80px;max-width:200px">
                                        <small><b>Assignee ID: {{ item.assignee_id }}</b></small>
                                        <br>
                                        <label class="access-label" v-if="item.assignee">
                                            {{ item.assignee.name }}
                                        </label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data() {
        return {
            loading: false,
            headers: [
                { text: 'Timestamp', width: '130px', sortable: false },
                { text: 'User', width: '150px', sortable: false },
                { text: 'Description', width: '200px', sortable: false },
                { text: 'Details', sortable: false },
            ],
            user_activities: [],
            sort_by: 'id',
            sort_desc: true,
        }
    },
    async mounted() {
        this.getUserActivity()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'companies/get_selected_company',
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getUserActivity() {
            this.loading = true
            this.$axios.get('api/user_activities/get_user_activities')
                .then(({ data }) => {
                    if (data.response) {
                        this.user_activities = data.data
                        this.loading = false
                    }
                })
        },
    },
    watch: {

    }

}
</script>

<style scoped lang="scss">
.access-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 12px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
    margin-right: 5px;
}
</style>