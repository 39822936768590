<template>
    <v-container class="mt-10" fluid>
        <v-card elevation="0" outlined>
            <v-card-title>
                Users
                <v-spacer></v-spacer>
                <v-btn @click="openAddUserDialog()" color="primary">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    Add User
                </v-btn>
            </v-card-title>
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-card-text v-else>
                <v-data-table :headers="headers" :items="users">
                    <template v-slot:item="{ item }">
                        <tr style="cursor:inherit;background-color:transparent;">
                            <td>{{ item.name }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.role.name }}</td>
                            <td>{{ $date(item.created_at).format('MMM DD, YYYY') }}</td>
                            <td>
                                <div>
                                    <label class="access-label">
                                        {{ item.access[0] ? item.access[0].company.name : 'None' }}
                                    </label>
                                    <label v-if="item.access.length > 1" class="access-label">
                                        <div class="tooltip">+{{ item.access.length - 1 }}
                                            <span class="tooltiptext">
                                                <label v-for="(access, index) in item.access" :key="index">
                                                    <label v-if="index > 0" class="access-label"
                                                        style="display:flex;flex-direction:column;">
                                                        {{ access.company.name }}
                                                    </label>
                                                </label>
                                            </span>
                                        </div>
                                    </label>
                                    <!-- <v-btn icon small @click="openCreateAccessDialog(item)"><v-icon
                                            small>mdi-plus</v-icon></v-btn> -->
                                </div>
                                <!-- <div v-else>
                                    <label class="access-label">
                                        All
                                    </label>
                                </div> -->
                            </td>
                            <td>
                                <div style="display:flex;">
                                    <v-btn icon @click="openUpdateUserDialog(item)"><v-icon
                                            small>mdi-pen</v-icon></v-btn>
                                    <v-btn icon @click="openDeleteUserDialog(item)"><v-icon
                                            small>mdi-delete</v-icon></v-btn>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-row justify="center">
            <v-dialog v-model="add_user_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Add User</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="new_user.name" label="Name *" outlined dense></v-text-field>
                        <v-text-field v-model="new_user.email" label="Email *" outlined dense></v-text-field>
                        <v-text-field v-model="new_user.password" label="Password *" outlined dense></v-text-field>
                        <v-select v-model="new_user.role_id" label="Role *" :items="get_roles" item-text="name"
                            item-value="id" outlined dense></v-select>
                        <v-select v-if="new_user.role_id > 1" v-model="new_user.access" label="Company Access"
                            item-text="name" item-value="id" multiple :items="get_companies" outlined dense></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeAddUserDialog()" text>Cancel</v-btn>
                        <v-btn @click="addUser()" color="primary">Add</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="update_user_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Update User Info</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="user_to_update.name" label="Name *" outlined dense></v-text-field>
                        <v-text-field v-model="user_to_update.email" label="Email *" outlined dense></v-text-field>
                        <!-- <v-text-field v-model="user_to_update.password" label="Password *" outlined
                            dense></v-text-field> -->
                        <v-select v-model="user_to_update.role_id" label="Role *" :items="get_roles" item-text="name"
                            item-value="id" outlined dense></v-select>
                        <v-select v-model="user_to_update.access" label="Company Access" item-text="name"
                            item-value="id" multiple :items="get_companies" outlined dense></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeUpdateUserDialog()" text>Cancel</v-btn>
                        <v-btn @click="updateUser()" color="primary">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="create_access_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Add Access</v-card-title>
                    <v-card-text>
                        <v-select v-model="access_to_create" label="Company Access" item-text="name" item-value="id"
                            multiple :items="get_companies" outlined dense></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeCreateAccessDialog()" text>Cancel</v-btn>
                        <v-btn @click="createAccess()" color="primary">Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="update_access_dialog" max-width="600" persistent>
                <v-card v-if="selected_access">
                    <v-card-title>Update Access to {{ selected_access.company.name }}</v-card-title>
                    <v-card-text>
                        <v-select v-model="selected_access.allow_suppliers" label="Allow Access to Suppliers Page"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" outlined dense></v-select>
                        <v-select v-model="selected_access.allow_packages" label="Allow Access to Packages Page"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" outlined dense></v-select>
                        <v-select v-model="selected_access.allow_reports" label="Allow Access to Reports Page"
                            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]" item-text="name"
                            item-value="value" outlined dense></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeUpdateAccessDialog()" text>Cancel</v-btn>
                        <v-btn @click="updateAccess()" color="primary">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="delete_access_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Are you sure you want to remove access to this company?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeDeleteAccessDialog()" text>Cancel</v-btn>
                        <v-btn @click="deleteAccess()" color="primary">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="delete_user_dialog" max-width="600" persistent>
                <v-card>
                    <v-card-title>Are you sure you want to delete this user?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeDeleteUserDialog()" text>Cancel</v-btn>
                        <v-btn @click="deleteUser()" color="primary">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data() {
        return {
            loading: false,
            headers: [
                { text: 'Name', width: '15%' },
                { text: 'Email Address', width: '25%' },
                { text: 'Role', width: '10%' },
                { text: 'Created At', width: '10%' },
                { text: 'Company Access', width: '30%' },
                { text: '', width: '1%' }
            ],
            users: [],
            add_user_dialog: false,
            new_user: {
                name: null,
                email: null,
                password: null,
                role_id: null,
                access: [],
            },
            update_user_dialog: false,
            user_to_update: {
                id: null,
                name: null,
                email: null,
                password: null,
                role_id: null,
                access: [],
            },
            create_access_dialog: false,
            selectable_companies: [],
            selected_user: null,
            access_to_create: null,
            delete_access_dialog: false,
            access_to_delete: null,
            delete_user_dialog: false,
            selected_access: null,
            update_access_dialog: false,
        }
    },
    created() {
    },
    mounted() {
        this.getUsers()
    },
    computed: {
        ...mapGetters({
            get_roles: 'auth/get_roles',
            get_companies: 'companies/get_companies',
            get_selected_company: 'companies/get_selected_company',
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getUsers() {
            this.loading = true
            this.$axios.get('api/users/get_users')
                .then(({ data }) => {
                    if (data.response) {
                        this.users = data.data
                        this.loading = false
                    }
                })
        },
        openUpdateAccessDialog(access) {
            this.selected_access = access
            this.update_access_dialog = true
        },
        closeUpdateAccessDialog() {
            this.selected_access = null
            this.update_access_dialog = false
        },
        async updateAccess() {
            let payload = {
                id: this.selected_access.id,
                allow_suppliers: this.selected_access.allow_suppliers,
                allow_packages: this.selected_access.allow_packages,
                allow_reports: this.selected_access.allow_reports,
            }
            await this.$axios.post('api/users/update_user_access', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateAccessDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openAddUserDialog() {
            this.add_user_dialog = true
        },
        closeAddUserDialog() {
            this.add_user_dialog = false
            this.new_user = {
                name: null,
                email: null,
                password: null,
                role_id: null,
                access: [],
            }
        },
        async addUser() {
            let payload = {
                ...this.new_user,
                company_id: this.get_selected_company.id
            }
            await this.$axios.post('api/users/create_user', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeAddUserDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openUpdateUserDialog(item) {
            this.update_user_dialog = true
            this.user_to_update.id = item.id
            this.user_to_update.name = item.name
            this.user_to_update.email = item.email
            this.user_to_update.password = item.password
            this.user_to_update.role_id = item.role_id
            item.access.forEach(access => {
                this.user_to_update.access.push(access.company_id)
            })
        },
        closeUpdateUserDialog() {
            this.update_user_dialog = false
            this.user_to_update = {
                id: null,
                name: null,
                email: null,
                password: null,
                role_id: null,
                access: [],
            }
        },
        async updateUser() {
            await this.$axios.post(`api/users/update_user/${this.user_to_update.id}`, this.user_to_update)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeUpdateUserDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeleteUserDialog(item) {
            this.selected_user = item
            this.delete_user_dialog = true
        },
        closeDeleteUserDialog() {
            this.delete_user_dialog = false
        },
        async deleteUser() {
            await this.$axios.delete(`api/users/delete_user/${this.selected_user.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteUserDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openCreateAccessDialog(item) {
            this.selected_user = item
            this.create_access_dialog = true
            this.selectable_companies = Object.assign([], this.get_companies)
            if (item.access) {
                item.access.forEach(access => {
                    this.get_companies.forEach((company, index) => {
                        if (access.company_id == company.id) {
                            this.selectable_companies.splice(index, 1)
                        }
                    })
                })
            }
        },
        closeCreateAccessDialog() {
            this.create_access_dialog = false
            this.selected_user = null
            this.selectable_companies = []
            this.access_to_create = null
        },
        async createAccess() {
            let payload = {
                access: this.access_to_create,
                user_id: this.selected_user.id,
            }
            await this.$axios.post('api/users/create_user_access', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeCreateAccessDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        },
        openDeleteAccessDialog(access) {
            this.delete_access_dialog = true
            this.access_to_delete = access
        },
        closeDeleteAccessDialog() {
            this.delete_access_dialog = false
            this.access_to_delete = null
        },
        async deleteAccess() {
            await this.$axios.delete(`api/users/delete_user_access/${this.access_to_delete.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success(data.message)
                        this.closeDeleteAccessDialog()
                        this.getUsers()
                    }
                    else {
                        this.$toast.error(data.message)
                    }
                })
        }
    },
    watch: {

    }

}
</script>

<style scoped lang="scss">
.access-label {
    color: #669E53;
    background-color: #E6F3E2;
    font-size: 12px;
    border: solid 1px #669E53;
    border-radius: 4px;
    padding: 3px 5px;
    margin-right: 5px;
    margin-bottom: 3px;
}

.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: white;
    color: black;
    text-align: left;
    padding: 5px 0;
    border-radius: 6px;
    max-height: 200px;
    overflow-y: scroll;

    /* Position the tooltip text - see examples below! */
    position: fixed;
    z-index: 10;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

:deep(::-webkit-scrollbar) {
    width: 10px;
}

:deep(::-webkit-scrollbar-thumb) {
    border-radius: 2px;
    /* background-color: rgba(0,0,0,.5); */
    background-color: #a5a5a5;
    color: #a5a5a5;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
</style>